import { detailsSmall, h5 } from "@/styles/typography";
import { Link } from "gatsby";
import styled from "styled-components";

const Container = styled.div`
  position: fixed;
  width: 100vw;
  top: 60px;
  height: 60px;
  z-index: 10;
  display: grid;
  align-items: center;
  grid-template-columns: 30px auto 30px;
  padding: 0 20px;
  box-sizing: border-box;
  background-color: #fff9f4;
  box-shadow: 0 0 14px 0px #0000001f;
  @media ${({ theme }) => theme.breakpoints.xs} {
    grid-template-columns: 160px auto 160px;
  }
`;

const Button = styled(Link)`
  text-decoration: none;
  display: flex;
  align-items: center;
  &:nth-of-type(1) {
    &:hover {
      img {
        transform: translateX(-6px);
      }
    }
  }
  &:nth-of-type(3) {
    &:hover {
      img {
        transform: translateX(6px);
      }
    }
  }
`;

const TitleLink = styled(Link)`
  grid-column: 2;
  text-decoration: none;
  color: ${({ theme }) => theme.textColors.primary};
`;

const Title = styled(h5)``;

const NavigationText = styled(detailsSmall)`
  color: ${(props) => props.theme.textColors.primary};
  white-space: nowrap;
  display: none;
  @media ${({ theme }) => theme.breakpoints.xs} {
    display: block;
  }
`;

const ArrowBack = styled.img`
  transition: all 0.2s ease;
  @media ${({ theme }) => theme.breakpoints.xs} {
    margin-right: 14px;
  }
`;

const ArrowForward = styled.img`
  transition: all 0.2s ease;
  @media ${({ theme }) => theme.breakpoints.xs} {
    margin-left: 14px;
  }
`;

const S = {
  Button,
  Container,
  TitleLink,
  Title,
  NavigationText,
  ArrowBack,
  ArrowForward,
};

export default S;
