import { nlToBr } from "@/src/helpers";
import { ILink } from "@/src/types";
import React from "react";

import S from "./CaseDescription.style";

export type TextSection = {
  header: string;
  text: string;
};
export interface ICaseDescription {
  caseheader: string;
  agencyname: string;
  teamname: string;
  scopename: string;
  freetext: string;
  sections: ReadonlyArray<TextSection>;
  linkTitle?: string;
  link?: ILink;
}

const CaseDescription: React.FC<ICaseDescription> = (data) => {
  const parts = [
    {
      condition: !!data.agencyname,
      node: (
        <>
          <S.Title>Agency:</S.Title>
          <S.Content>{nlToBr(data.agencyname)}</S.Content>
        </>
      ),
    },
    {
      condition: !!data.teamname,
      node: (
        <>
          <S.Title>Team:</S.Title>
          <S.Content>{nlToBr(data.teamname)}</S.Content>
        </>
      ),
    },
    {
      condition: !!data.scopename,
      node: (
        <>
          <S.Title>Scope:</S.Title>
          <S.Content>{nlToBr(data.scopename)}</S.Content>
        </>
      ),
    },
    {
      condition: !!data.freetext,
      node: (
        <>
          <S.Content>{nlToBr(data.freetext)}</S.Content>
        </>
      ),
    },
    {
      condition: !!data.linkTitle && !!data.link,
      node: (
        <>
          <S.Title>{data.linkTitle}</S.Title>
          <S.Content>
            <a href={data.link?.url} target={data.link?.target}>
              {data.link?.title}
            </a>
          </S.Content>
        </>
      ),
    },
  ];

  return (
    <S.Container>
      <S.MainInner>
        <S.CaseHeader>{data.caseheader}</S.CaseHeader>
        <S.TextContainer>
          <S.AgencyContainer>
            {parts
              .filter(({ condition }) => condition)
              .map((i) => i.node)
              .reduce((carry, node, index, array) => {
                carry.push(node);

                if (index < array.length - 1) {
                  carry.push(<p key={`divider_${index}`}>/</p>);
                }

                return carry;
              }, [] as React.ReactNode[])}
          </S.AgencyContainer>
          <S.BackgroundCaseContainer>
            {(data.sections || []).map((section) => (
              <S.Section key={section.header}>
                <S.SectionTitle>{section.header}</S.SectionTitle>
                <S.TextWrapper
                  dangerouslySetInnerHTML={{ __html: section.text }}
                />
              </S.Section>
            ))}
          </S.BackgroundCaseContainer>
        </S.TextContainer>
      </S.MainInner>
    </S.Container>
  );
};

export default CaseDescription;
1;
