import { detailsBig, h1, paragraph, paragraphBig } from "@/styles/typography";
import styled from "styled-components";

const Container = styled.div`
  position: relative;
  width: 100%;
  @media only screen and (min-width: 701px) {
    display: none;
  }
`;

const MainInner = styled.div`
  max-width: 1440px;
  margin: 0 auto;
  padding: 17px 20px 20px;
  @media ${({ theme }) => theme.breakpoints.sm} {
    padding: 0 40px;
    margin: 40px auto;
  }
  @media ${({ theme }) => theme.breakpoints.md} {
    padding: 0 100px;
    margin: 100px auto;
  }
`;

const TextContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

const Title = styled(paragraph)`
  font-style: italic;
`;

const Content = styled(paragraph)``;
const BackgroundCaseContainer = styled.div`
  display: flex;
  flex-direction: column;
  text-align: left;
`;

const S = {
  Container,
  MainInner,
  TextContainer,
  BackgroundCaseContainer,
  Title,
  Content,
};

export default S;
