import { detailsSmall, paragraphBig, h3 } from "@/styles/typography";
import styled from "styled-components";

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  @media ${({ theme }) => theme.breakpoints.sm} {
    margin: 20px 0;
    flex-direction: row;
  }
`;

const Half = styled.div`
  overflow: hidden;
  background-color: ${({ theme }) => theme.factColors.tertiary};

  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 40px 20px;

  @media ${({ theme }) => theme.breakpoints.sm} {
    width: calc(50% - 10px);
  }
  @media ${({ theme }) => theme.breakpoints.md} {
    padding: 40px;
  }
`;

const Title = styled(h3)`
  margin: 0 auto 8px auto;
  width: 60%;

  @media ${({ theme }) => theme.breakpoints.sm} {
    width: 100%;
  }
`;

const Text = styled(paragraphBig)`
  max-width: 600px;
  margin: 0 auto;

  @media ${({ theme }) => theme.breakpoints.md} {
    width: 425px;
  }
`;

const ButtonContainer = styled.a`
  appearance: none;
  border-radius: 0;
  cursor: pointer;
  border: 1px solid ${(props) => props.theme.textColors.primary};
  background-color: transparent;
  margin: auto auto 0;
  display: flex;
  align-items: center;
  padding: 13px 24px;
  margin-top: 30px;
  text-decoration: none;
  &:hover {
    img {
      transform: translateX(6px);
    }
  }
`;
const ButtonText = styled(detailsSmall)`
  color: ${({ theme }) => theme.textColors.primary};
`;

const ArrowNext = styled.img`
  margin-left: 10px;
  transition: all 0.2s ease;
`;

const MobileImage = styled.img`
  width: 100%;
  overflow: hidden;
  display: block;
  object-fit: cover;
  @media ${({ theme }) => theme.breakpoints.sm} {
    width: calc(50% - 10px);
    order: 2;
  }
  @media ${({ theme }) => theme.breakpoints.md} {
    display: none;
  }
`;

const DesktopImageContainer = styled.div`
  display: none;
  position: relative;
  @media ${({ theme }) => theme.breakpoints.md} {
    display: block;
    width: calc(50% - 10px);
  }
`;

const DesktopImage = styled.img`
  object-fit: cover;
  object-position: left;
  width: 100%;
  height: 100%;
`;

const ImageLine = styled.div`
  width: 20px;
  height: 100%;
  background: white;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  @media only screen and (max-width: 992px) {
    display: none;
  }
`;

const S = {
  Container,
  Half,
  Title,
  Text,
  ButtonContainer,
  ButtonText,
  ArrowNext,
  MobileImage,
  DesktopImage,
  DesktopImageContainer,
  ImageLine,
};

export default S;
