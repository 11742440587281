import { IImage } from "@/src/types";
import React from "react";
import { nlToBr } from "@/src/helpers";

import ArrowForward from "../../../assets/arrow-right-black.svg";

import S from "./Contact.style";

export interface IContact {
  title: string;
  text: string;
  link?: {
    title: string;
    url: string;
  };
  imageDesktop: IImage;
  imageMobile: IImage;
}

const Contact: React.FC<IContact> = (data) => {
  return (
    <S.Container>
      <S.MobileImage
        src={data.imageMobile.sourceUrl}
        alt={data.imageMobile.altText}
      />
      <S.Half>
        <S.Title>{data.title}</S.Title>
        <S.Text>{nlToBr(data.text)}</S.Text>
        {data.link && (
          <S.ButtonContainer href={data.link.url}>
            <S.ButtonText>{data.link.title}</S.ButtonText>
            <S.ArrowNext src={ArrowForward} />
          </S.ButtonContainer>
        )}
      </S.Half>
      <S.DesktopImageContainer>
        <S.DesktopImage
          src={data.imageDesktop.sourceUrl}
          alt={data.imageDesktop.altText}
        />
        <S.ImageLine></S.ImageLine>
      </S.DesktopImageContainer>
    </S.Container>
  );
};

export default Contact;
