import { nlToBr } from "@/src/helpers";
import React from "react";
import { TextSection } from "../CaseDescription";

import S from "./CaseDescriptionMobile.style";
import CaseS from "../CaseDescription/CaseDescription.style";

export interface ICaseDescriptionMobile {
  sections: ReadonlyArray<TextSection>;
}

const CaseDescriptionMobile: React.FC<ICaseDescriptionMobile> = (data) => {
  return (
    <S.Container>
      <S.MainInner>
        <S.TextContainer>
          <S.BackgroundCaseContainer>
            {(data.sections || []).map((section) => (
              <CaseS.Section key={section.header}>
                <CaseS.SectionTitle>{section.header}</CaseS.SectionTitle>
                <CaseS.TextWrapper
                  dangerouslySetInnerHTML={{ __html: section.text }}
                />
              </CaseS.Section>
            ))}
          </S.BackgroundCaseContainer>
        </S.TextContainer>
      </S.MainInner>
    </S.Container>
  );
};

export default CaseDescriptionMobile;
1;
