import styled from "styled-components";

const Image = styled.img`
  margin-top: 120px;
  width: 100%;
  object-fit: cover;
`;

export default {
  Image,
};
