import * as React from "react";
import { ThemeProvider } from "styled-components";

import Body from "@/components/core-blocks/Body";
import Main from "@/components/core-blocks/Main";
import Header from "@/components/core-blocks/Header";
import Seo, { ISeo } from "@/components/core-blocks/Seo";
import Flexible, { IFlexible } from "@/src/imports/Flexible";
import LocationProvider, {
  ILocationProvider,
} from "@/contexts/LocationProvider";
import GlobalStyle from "@/styles/global";
import { primary } from "@/styles/themes/primary.theme";
import CaseTopBar from "@/components/core-blocks/CaseTopBar";
import CaseFeaturedImage from "@/components/core-blocks/CaseFeaturedImage";
import CaseDescription, {
  TextSection,
} from "@/components/core-blocks/CaseDescription";
import CaseDescriptionMobile from "@/components/core-blocks/CaseDescriptionMobile";
import Footer from "@/components/core-blocks/Footer";
import Contact, { IContact } from "@/components/core-blocks/Contact";
import { ILink, IPageSettings } from "../types";

export type ICaseTemplate = {
  location: ILocationProvider;
  pageContext: {
    title: string;
    acf: IFlexible;
    pageSettings: IPageSettings;
    caseInfo: {
      agencyname: string;
      teamname: string;
      scopename: string;
      freetext: string;
      caseTextSections: ReadonlyArray<TextSection>;
      contact: IContact;
      showContact: boolean;
      linkTitle?: string;
      link?: ILink;
    };
    seo: ISeo;
    id: string;
    featuredImage: {
      node: {
        sourceUrl: string;
      };
    };
  };
};

const PageTemplate: React.FC<ICaseTemplate> = ({
  location: { href, pathname },
  pageContext: { title, seo, acf, id, featuredImage, caseInfo, pageSettings },
}) => {
  return (
    <ThemeProvider theme={primary}>
      <LocationProvider {...{ href, pathname }}>
        <GlobalStyle />
        <Seo {...seo} />
        <Body>
          <Header hero={false} />
          <CaseTopBar currentId={id} />
          <Main>
            {featuredImage && (
              <CaseFeaturedImage url={featuredImage.node.sourceUrl} />
            )}
            <CaseDescription
              caseheader={title}
              agencyname={caseInfo.agencyname}
              teamname={caseInfo.teamname}
              scopename={caseInfo.scopename}
              freetext={caseInfo.freetext}
              sections={caseInfo.caseTextSections}
              linkTitle={caseInfo.linkTitle}
              link={caseInfo.link}
            />
            <Flexible components={acf.components} />
            <CaseDescriptionMobile sections={caseInfo.caseTextSections} />
            {caseInfo.showContact && caseInfo.contact && (
              <Contact {...caseInfo.contact} />
            )}
            <Footer mobileFooterImage={pageSettings.mobileFooterImage} />
          </Main>
        </Body>
      </LocationProvider>
    </ThemeProvider>
  );
};

export default PageTemplate;
