import React from "react";

import S from "./CaseFeaturedImage.style";

type Props = {
  url: string;
};

const CaseFeaturedImage: React.FC<Props> = (props) => {
  return <S.Image src={props.url} alt="featured-image" />;
};

export default CaseFeaturedImage;
