import {
  detailsBig,
  h1,
  paragraph,
  paragraphBig,
  paragraphBigCss,
} from "@/styles/typography";
import styled from "styled-components";

const Container = styled.div`
  position: relative;
  width: 100%;
`;

const MainInner = styled.div`
  max-width: 1440px;
  margin: 0 auto;
  padding: 17px 20px 20px;
  @media ${({ theme }) => theme.breakpoints.sm} {
    padding: 0 40px;
    margin: 40px auto;
  }
  @media ${({ theme }) => theme.breakpoints.md} {
    padding: 0 100px;
    margin: 100px auto;
  }
`;

const CaseHeader = styled(h1)`
  margin-bottom: 8px;
  text-align: left;
  @media ${({ theme }) => theme.breakpoints.xs} {
    margin-bottom: 30px;
  }
  @media ${({ theme }) => theme.breakpoints.md} {
    font-size: 60px;
    margin-bottom: 60px;
  }
`;

const TextContainer = styled.div`
  display: flex;
  flex-direction: row;
`;
const AgencyContainer = styled.div`
  flex: 1;
  text-align: left;
`;

const Title = styled(paragraph)`
  font-style: italic;
  color: ${({ theme }) => theme.textColors.primary};
`;

const Content = styled(paragraph)`
  color: ${({ theme }) => theme.textColors.primary};
  a {
    color: inherit;
  }
`;
const ContentLink = styled.a`
  color: ${({ theme }) => theme.textColors.primary};
`;
const BackgroundCaseContainer = styled.div`
  display: flex;
  flex-direction: column;
  text-align: left;
  width: 75%;
  padding-left: 100px;
  @media only screen and (max-width: 700px) {
    display: none;
  }
`;

const Section = styled.div`
  margin-bottom: 40px;
  &:last-of-type {
    margin-bottom: 0;
  }
`;

const SectionTitle = styled(detailsBig)``;

const TextWrapper = styled.div`
  ${paragraphBigCss}

  a {
    color: ${({ theme }) => theme.textColors.primary};
  }
`;

const SectionText = styled(paragraphBig)`
  line-height: 162%;
`;

const S = {
  Container,
  MainInner,
  TextContainer,
  AgencyContainer,
  BackgroundCaseContainer,
  CaseHeader,
  Title,
  Content,
  Section,
  SectionTitle,
  SectionText,
  TextWrapper,
};

export default S;
