import * as React from "react";

import {
  ICase,
  useCasesData,
} from "@/components/layout-blocks/Cases/useCasesData";

// @ts-ignore
import ArrowForward from "../../../assets/arrow-right-black.svg";
// @ts-ignore
import ArrowBack from "../../../assets/arrow-left-black.svg";

import S from "./CaseTopBar.style";

type Props = {
  currentId: string;
};

const CaseTopBar: React.FC<Props> = (props) => {
  const cases = useCasesData();

  const currentIndex = cases.findIndex((c) => c.id === props.currentId);
  const nextIndex = currentIndex === cases.length - 1 ? 0 : currentIndex + 1;
  const prevIndex = currentIndex === 0 ? cases.length - 1 : currentIndex - 1;

  return (
    <S.Container>
      <S.Button to={cases[prevIndex].uri}>
        <S.ArrowBack src={ArrowBack} />
        <S.NavigationText>PREVIOUS CASE</S.NavigationText>
      </S.Button>

      <S.TitleLink to="/work/">
        <S.Title>{cases[currentIndex].title}</S.Title>
      </S.TitleLink>

      <S.Button to={cases[nextIndex].uri}>
        <S.NavigationText>NEXT CASE</S.NavigationText>
        <S.ArrowForward src={ArrowForward} />
      </S.Button>
    </S.Container>
  );
};
export default CaseTopBar;
